/**
 * @name Hotel Room Booking System
 * @autor Mr. Josia Yvan (M1 MSI_ESTI)
 * @description Hotel Room Booking and Management System Software ~ Developed By Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 * @version v0.0.1
 *
 */

import {
  EnvironmentOutlined, LockOutlined, UserOutlined
} from '@ant-design/icons';
import {
  Button, Form, Input
} from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { reFetchData } from '../../store/slice/appSlice';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';

function CreateUser() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // function to handle register new user
  const onFinish = (values) => {
    setLoading(true);
    const data = {
      number: values.number,
      firstname: values.firstname,
      quartier: values.quartier,
      password: values.password
    };

    ApiService.post('/auth/signup', data)
      .then((response) => {
        console.log(response, '06523');
        setLoading(false);
        if (response?.result_code === 0) {
          notificationWithIcon('success', 'SUCCESS', response?.result?.message || 'New user registration successful');
          form.resetFields();
          dispatch(reFetchData());
        } else {
          notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
        }
      })
      .catch((err) => {
        setLoading(false);
        const error = err?.response?.data?.result?.error;
        if (typeof error !== typeof 'string') {
          (error?.number !== '') && notificationWithIcon('error', 'ERROR', error?.number || 'Sorry! Something went wrong. App server error');
          (error?.password !== '') && notificationWithIcon('error', 'ERROR', error?.password || 'Sorry! Something went wrong. App server error');
          (error?.firstname !== '') && notificationWithIcon('error', 'ERROR', error?.firstname || 'Sorry! Something went wrong. App server error');
          (error?.quartier !== '') && notificationWithIcon('error', 'ERROR', error?.quartier || 'Sorry! Something went wrong. App server error');
        } else {
          notificationWithIcon('error', 'ERROR', error || 'Sorry! Something went wrong. App server error');
        }
      });
  };

  return (
    <Form
      form={form}
      className='login-form'
      name='create-new-user'
      onFinish={onFinish}
      layout='vertical'
    >
      <div className='two-grid-column'>
        <Form.Item
          className='w-full md:w-1/2'
          label='Number'
          name='number'
          rules={[{
            required: true,
            message: 'Please input the user Number!'
          }]}
        >
          <Input
            prefix={<UserOutlined className='site-form-item-icon' />}
            placeholder='Number'
            size='large'
            type='text'
            allowClear
          />
        </Form.Item>

        <Form.Item
          className='w-full md:w-1/2'
          label='Firstname'
          name='firstname'
          rules={[{
            required: true,
            message: 'Please input the Firstname!'
          }]}
        >
          <Input
            prefix={<UserOutlined className='site-form-item-icon' />}
            placeholder='Firstname'
            size='large'
            type='text'
            allowClear
          />
        </Form.Item>
      </div>

      <div className='two-grid-column'>
        <Form.Item
          className='w-full md:w-1/2'
          label='Quartier'
          name='quartier'
          rules={[{
            required: true,
            message: 'Please input the Quartier!'
          }]}
        >
          <Input
            prefix={<EnvironmentOutlined className='site-form-item-icon' />}
            placeholder='Quartier'
            size='large'
            type='text'
            allowClear
          />
        </Form.Item>

        <Form.Item
          className='w-full md:w-[49.5%]'
          label='Password'
          name='password'
          rules={[{
            required: true,
            message: 'Please input your Password!'
          }]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockOutlined className='site-form-item-icon' />}
            placeholder='Password'
            size='large'
            type='password'
            allowClear
          />
        </Form.Item>
      </div>

      <Form.Item
        className='w-full md:w-[49.5%]'
        label='Confirm Password'
        name='confirmPassword'
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your Password!'
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords do not match!'));
            }
          })
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className='site-form-item-icon' />}
          placeholder='Confirm Password'
          size='large'
          type='password'
          allowClear
        />
      </Form.Item>

      <Form.Item>
        <Button
          className='login-form-button mt-4'
          htmlType='submit'
          type='primary'
          size='large'
          loading={loading}
          disabled={loading}
        >
          Register User
        </Button>
      </Form.Item>
    </Form>
  );
}

export default React.memo(CreateUser);
