/**
 * @name User Details Component
 * @description Displays detailed information about a user fetched from an API.
 * @version v0.0.1
 * @author Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 */

// External imports
import { ExclamationCircleFilled } from '@ant-design/icons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button, Descriptions, Image, Modal, Result, Skeleton, Tag
} from 'antd';

// Internal imports
import useFetchData from '../../hooks/useFetchData';
import { reFetchData } from '../../store/slice/appSlice';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';
import { roleAsResponse, userSubscribedAsResponse } from '../../utils/responseAsStatus';
import ProfileEditModal from '../shared/ProfileEditModal';

// Destructuring Modal from Ant Design
const { confirm } = Modal;

/**
 * User Details Component
 * @param {Object} props - Component props
 * @param {string} props.id - User ID to fetch details
 */
function UserDetails({ id }) {
  const dispatch = useDispatch();

  // Fetch user-details API data
  const [loading, error, response] = useFetchData(`/user/${id}`);
  const [editProfileModal, setEditProfileModal] = useState(false);

  // Function to handle unblocking a user
  const deleteUser = () => {
    confirm({
      title: 'DELETE USER',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to DELETE this user?',
      onOk() {
        return new Promise((resolve, reject) => {
          ApiService.delete(`/user/${id}`)
            .then((res) => {
              if (res?.result_code === 0) {
                notificationWithIcon('success', 'SUCCESS', res?.result?.message || 'User deleted successfully');
                dispatch(reFetchData());
                resolve();
              } else {
                notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
                reject();
              }
            })
            .catch((err) => {
              notificationWithIcon('error', 'ERROR', err?.response?.data?.result?.error?.message || err?.response?.data?.result?.error || 'Sorry! Something went wrong. App server error');
              reject();
            });
        }).catch(() => notificationWithIcon('error', 'ERROR', 'Oops, errors occurred!'));
      }
    });
  };

  // Rendering the user details component
  return (
    <>
      <Skeleton loading={loading} paragraph={{ rows: 10 }} active avatar>
        {error ? (
          <Result
            title='Failed to fetch'
            subTitle={error}
            status='error'
          />
        ) : (
          <Descriptions
            title='User Information'
            bordered
            extra={(
            // eslint-disable-next-line react/jsx-indent
            <>
              <Button
                onClick={() => setEditProfileModal(true)}
                shape='default'
                type='primary'
                size='middle'
              >
                Edit User
              </Button>
              {'\t'}
              <Button onClick={deleteUser} type='default' danger>
                Delete User
              </Button>
            </>
            )}
          >
            <Descriptions.Item label='Avatar' span={3}>
              {response?.data?.avatar ? (
                <Image
                  className='!w-[100px] !h-[100px]'
                  src={response?.data?.avatar}
                  crossOrigin='anonymous'
                  alt='user-image'
                />
              ) : 'N/A'}
            </Descriptions.Item>

            <Descriptions.Item label='Numéro'>
              {response?.data?.number}
            </Descriptions.Item>
            <Descriptions.Item label='Prénom' span={2}>
              {response?.data?.firstname}
            </Descriptions.Item>
            <Descriptions.Item label='Quartier'>
              {response?.data?.quartier}
            </Descriptions.Item>
            <Descriptions.Item label='Date de registre' span={2}>
              {response?.data?.registrationDate}
            </Descriptions.Item>

            <Descriptions.Item label='En ligne'>
              <Tag
                className='w-[80px] text-center uppercase'
                color={response?.data?.isOnline ? 'magenta' : 'purple'}
              >
                {response?.data?.isOnline ? 'Actif' : 'Inactif' }
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label='Status d`abonnement' span={2}>
              <Tag
                className='w-[100px] text-center uppercase'
                color={userSubscribedAsResponse(response?.data?.isSubscribed).color}
              >
                {userSubscribedAsResponse(response?.data?.isSubscribed).level}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label='Première inscription'>
              <Tag
                className='w-[50px] text-center uppercase'
                color={response?.data?.isFirstSubscription ? 'success' : 'error'}
              >
                {response?.data?.isFirstSubscription ? 'Yes' : 'No'}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label='Date de fin d`abonnement' span={2}>
              {response?.data?.expirationDate?.split('T')[0] || 'N/A'}
            </Descriptions.Item>

            <Descriptions.Item label='Date d`abonnement'>
              {response?.data?.payDate?.split('T')[0]}
            </Descriptions.Item>
            <Descriptions.Item label='Date d`inscription' span={2}>
              {response?.data?.createdAt?.split('T')[0]}
            </Descriptions.Item>

            <Descriptions.Item label='Role' span={3}>
              <Tag
                className='w-[100px] text-center uppercase'
                color={roleAsResponse(response?.data?.role).color}
              >
                {roleAsResponse(response?.data?.role).level}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label='Appareil' span={3}>
              {response?.data?.deviceToken}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Skeleton>

      {/* profile edit modal component */}
      {editProfileModal && (
        <ProfileEditModal
          editProfileModal={editProfileModal}
          setEditProfileModal={setEditProfileModal}
          user={response?.data}
        />
      )}
    </>
  );
}

export default React.memo(UserDetails);
