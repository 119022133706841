import {
  Button,
  Form, Modal, Upload
} from 'antd';
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';

function ImageModal({
  statusUpdateModal, setStatusUpdateModal, setFetchAgain, uploadUrl
}) {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinish = (values) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append('image', values.my_image[0].originFileObj);

    ApiService.post(uploadUrl, formdata, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((res) => {
        setLoading(false);
        if (res?.result_code === 0) {
          notificationWithIcon('success', 'SUCCESS', res?.result?.message || 'Image update successful');
          setStatusUpdateModal((prevState) => ({ ...prevState, open: false, status: null }));
          setFetchAgain((prevState) => !prevState); // Déclenche le refetch
        } else {
          notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationWithIcon('error', 'ERROR', err?.response?.data?.result?.error?.message || err?.response?.data?.result?.error || 'Sorry! Something went wrong. App server error');
      });
  };

  return (
    <Modal
      title='Update Image :'
      open={statusUpdateModal?.open}
      footer={null}
    >
      <Form
        form={form}
        className='login-form'
        name='image-edit-form'
        onFinish={onFinish}
        layout='vertical'
      >
        <Form.Item
          name='my_image'
          label='Images'
          valuePropName='file'
          getValueFromEvent={normFile}
          rules={[{
            required: true,
            message: 'Please input your Image!'
          }]}
        >
          <Upload
            listType='picture-card'
            onChange={({ fileList: newFileList }) => setFileList(newFileList)}
            fileList={fileList}
            beforeUpload={() => false}
            name='originalname'
            maxCount={0}
          >
            {fileList.length >= 1 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>
                  Upload
                </div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            className='login-form-button mt-4'
            htmlType='submit'
            type='primary'
            size='large'
            loading={loading}
            disabled={loading || fileList.length === 0}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ImageModal;
