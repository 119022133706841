exports.formatNumber = (inputString) => {
  // Extraire chaque segment
  const part1 = inputString.substring(0, 3);
  const part2 = inputString.substring(3, 5);
  const part3 = inputString.substring(5, 8);
  const part4 = inputString.substring(8, 10);
  const part5 = inputString.substring(10, 20);

  const resultString = `${part1} ${part2} ${part3} ${part4} ${part5}`;

  return resultString;
};
