import {
  Button, Input, Modal, Select
} from 'antd';
import React, { useEffect, useState } from 'react';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';

function RadioUpdateModal({ statusUpdateModal, setStatusUpdateModal, setFetchAgain }) {
  const [type, setType] = useState([
    { value: '192', label: '192', disabled: false },
    { value: '128', label: '128', disabled: false },
    { value: '48', label: '48', disabled: false }
  ]);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [link, setLink] = useState('');

  useEffect(() => {
    setLink(statusUpdateModal?.link);
    if (statusUpdateModal?.status === '192') {
      setType([
        { value: '192', label: '192', disabled: true },
        { value: '128', label: '128', disabled: false },
        { value: '48', label: '48', disabled: false }
      ]);
    } else if (statusUpdateModal?.status === '128') {
      setType([
        { value: '192', label: '192', disabled: false },
        { value: '128', label: '128', disabled: true },
        { value: '48', label: '48', disabled: false }
      ]);
    } else if (statusUpdateModal?.status === '48') {
      setType([
        { value: '192', label: '192', disabled: false },
        { value: '128', label: '128', disabled: false },
        { value: '48', label: '48', disabled: true }
      ]);
    }
  }, [statusUpdateModal]);

  const handleUpdateStatus = () => {
    if (status === null) {
      notificationWithIcon('error', 'ERROR', 'Please select a Type first to update radio');
    } else {
      setLoading(true);
      const updateData = {
        type: status,
        link
      };
      ApiService.put(
        `/radio/${statusUpdateModal?.id}`,
        updateData
      )
        .then((res) => {
          setLoading(false);
          if (res?.result_code === 0) {
            notificationWithIcon('success', 'SUCCESS', res?.result?.message || 'Radio update successful');
            setStatusUpdateModal((prevState) => ({ ...prevState, open: false, status: null }));
            setFetchAgain((prevState) => !prevState);
          } else {
            notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
          }
        })
        .catch((err) => {
          setLoading(false);
          notificationWithIcon('error', 'ERROR', err?.response?.data?.result?.error?.message || err?.response?.data?.result?.error || 'Sorry! Something went wrong. App server error');
        });
    }
  };

  return (
    <Modal
      title='Update Radio:'
      visible={statusUpdateModal?.open}
      onOk={() => setStatusUpdateModal((prevState) => ({ ...prevState, open: false, status: null }))}
      onCancel={() => setStatusUpdateModal((prevState) => ({ ...prevState, open: false, status: null }))}
      footer={[
        <Button
          onClick={() => setStatusUpdateModal((prevState) => ({ ...prevState, open: false, status: null }))}
          key='back'
        >
          Cancel
        </Button>,
        <Button
          onClick={handleUpdateStatus}
          type='primary'
          key='submit'
          disabled={loading || status === null}
          loading={loading}
        >
          Ok
        </Button>
      ]}
    >
      <Input
        className='w-full my-5'
        placeholder='Enter radio link'
        value={link}
        onChange={(e) => setLink(e.target.value)}
      />
      <Select
        className='w-full my-5'
        placeholder='-- select radio type --'
        optionFilterProp='children'
        options={type}
        size='large'
        allowClear
        value={status}
        onChange={(value) => setStatus(value)}
      />
    </Modal>
  );
}

export default RadioUpdateModal;
