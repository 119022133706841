/* eslint-disable no-shadow */
import {
  Table, Button, Modal, Form, Input, message, Popconfirm, Skeleton, Result
} from 'antd';
import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import useFetchData from '../../hooks/useFetchData';
import ApiService from '../../utils/apiService';

function Notification() {
  const [fetchAgain, setFetchAgain] = useState(false);
  const [loading, error, response] = useFetchData('/notification/all', fetchAgain);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    console.log(response?.data?.notifications, 'lk');
    if (fetchAgain) {
      setFetchAgain(false);
    }
  }, [response]);

  const handleAddNotification = async (values) => {
    try {
      const { data } = await ApiService.post('/notification', values);
      message.success(data.message);
      setFetchAgain(true);
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error('Failed to add notification.');
    }
  };

  const handleDeleteNotification = async (id) => {
    try {
      await axios.delete(`/notification/${id}`);
      message.success('Notification deleted.');
      setFetchAgain(true);
    } catch (error) {
      message.error('Failed to delete notification.');
    }
  };

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'To User',
      dataIndex: 'toUser',
      key: 'toUser'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Popconfirm title='Are you sure to delete this notification?' onConfirm={() => handleDeleteNotification(record.id)} okText='Yes' cancelText='No'>
          <Button danger>Delete</Button>
        </Popconfirm>
      )
    }
  ];

  return (
    <Skeleton loading={loading} active>
      {error ? (
        <Result title='Failed to fetch' subTitle={error} status='error' />
      ) : (
        <>
          <Button type='primary' icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)} style={{ marginBottom: 16 }}>
            Add Notification
          </Button>
          <Table columns={columns} dataSource={response?.data?.notifications} rowKey='_id' />
          <Modal title='Add Notification' visible={isModalVisible} onCancel={() => setIsModalVisible(false)} onOk={() => form.submit()}>
            <Form form={form} layout='vertical' onFinish={handleAddNotification}>
              <Form.Item name='label' label='Label' rules={[{ required: true, message: 'Please input the label!' }]}>
                <Input />
              </Form.Item>
              <Form.Item name='description' label='Description' rules={[{ required: true, message: 'Please input the description!' }]}>
                <Input />
              </Form.Item>
              <Form.Item name='toUser' label='To User' rules={[{ required: true, message: 'Please input the user!' }]}>
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </Skeleton>
  );
}

export default React.memo(Notification);
