import {
  Button, Form, Input, Modal, Select
} from 'antd';
import React, { useState, useEffect } from 'react';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';

const { Option } = Select;

function EditModal({
  statusUpdateModal, setStatusUpdateModal, setFetchAgain, currentNumber, currentType
}) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (statusUpdateModal.open) {
      const number = currentType === 'whatsapp' ? currentNumber.replace('https://wa.me/', '') : currentNumber;
      form.setFieldsValue({
        type: currentType,
        number
      });
    }
  }, [statusUpdateModal.open, currentNumber, currentType, form]);

  const onFinish = (values) => {
    setLoading(true);
    const formattedNumber = values.type === 'whatsapp' ? `https://wa.me/${values.number}` : values.number;

    ApiService.put(`/number/${statusUpdateModal.id}`, { ...values, number: formattedNumber })
      .then((res) => {
        setLoading(false);
        if (res?.result_code === 0) {
          notificationWithIcon('success', 'SUCCESS', res?.result?.message || 'Update successful');
          setStatusUpdateModal({ open: false, id: null });
          setFetchAgain((prevState) => !prevState); // Reload the page or refetch data
        } else {
          notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notificationWithIcon('error', 'ERROR', err?.response?.data?.result?.error?.message || err?.response?.data?.result?.error || 'Sorry! Something went wrong. App server error');
      });
  };

  return (
    <Modal title='Modifier Number' open={statusUpdateModal.open} footer={null} onCancel={() => setStatusUpdateModal({ open: false, id: null })}>
      <Form form={form} name='edit-number-form' onFinish={onFinish} layout='vertical'>
        <Form.Item name='type' label='Type' rules={[{ required: true, message: 'Please select the type!' }]}>
          <Select>
            <Option value='numero'>Numéro</Option>
            <Option value='whatsapp'>WhatsApp</Option>
          </Select>
        </Form.Item>
        <Form.Item name='number' label='Number' rules={[{ required: true, message: 'Please input the number!' }]}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loading} disabled={loading}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditModal;
