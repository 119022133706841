import {
  Button, Table, Result, Skeleton
} from 'antd';
import React, { useState } from 'react';
import useFetchData from '../../hooks/useFetchData';
import EditModal from '../shared/EditModal';

function Infoline() {
  const [fetchAgain, setFetchAgain] = useState(false); // Track data refetch
  const [loading, error, response] = useFetchData('/number/all', fetchAgain);
  const [editModal, setEditModal] = useState({
    open: false, id: null, currentNumber: '', currentType: ''
  });

  const handleEdit = (record) => {
    setEditModal({
      open: true,
      // eslint-disable-next-line no-underscore-dangle
      id: record._id,
      currentNumber: record.number,
      currentType: record.type
    });
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number'
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString()
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => new Date(text).toLocaleString()
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type='primary' onClick={() => handleEdit(record)}>
          Modifier
        </Button>
      )
    }
  ];

  return (
    <Skeleton loading={loading} active>
      {error ? (
        <Result title='Failed to fetch' subTitle={error} status='error' />
      ) : (
        <>
          <Table dataSource={response?.data.numbers || []} columns={columns} rowKey='_id' />
          {editModal.open && <EditModal currentNumber={editModal.currentNumber} currentType={editModal.currentType} statusUpdateModal={editModal} setStatusUpdateModal={setEditModal} setFetchAgain={setFetchAgain} />}
        </>
      )}
    </Skeleton>
  );
}

export default React.memo(Infoline);
