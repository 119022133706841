/**
 * @name Radio EMIA
 * @author Mr. Josia Yvan (M1 MSI_ESTI)
 * @description System API and Management System Software ~ Developed By Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 * @version v0.0.1
 *
 */
export const userStatusAsResponse = (status) => {
  if (status === 'register') {
    return {
      color: '#108ee9',
      level: 'REGISTER'
    };
  }
  if (status === 'login') {
    return {
      color: '#87d068',
      level: 'LOGIN'
    };
  }
  if (status === 'logout') {
    return {
      color: '#2db7f5',
      level: 'LOGOUT'
    };
  }
  if (status === 'blocked') {
    return {
      color: '#f55000',
      level: 'BLOCKED'
    };
  }
  return {
    color: 'default',
    level: 'UNKNOWN'
  };
};

export const userSubscribedAsResponse = (status) => {
  if (status === true) {
    return {
      color: '#108ee9',
      level: 'Abonné(e)'
    };
  }
  if (status === false) {
    return {
      color: '#2db7f5',
      level: 'Désabonné(e)'
    };
  }

  return {
    color: 'default',
    level: 'UNKNOWN'
  };
};

export const roleAsResponse = (role) => {
  if (role === 'USER_ROLE') {
    return {
      color: '#87d068',
      level: 'USER_ROLE'
    };
  }
  if (role === 'ADMIN_ROLE') {
    return {
      color: '#f55000',
      level: 'ADMIN_ROLE'
    };
  }
  return {
    color: 'default',
    level: 'USER_ROLE'
  };
};

export const roomTypeAsColor = (type) => {
  if (type === 'single') {
    return 'purple';
  }
  if (type === 'couple') {
    return 'magenta';
  }
  if (type === 'family') {
    return 'volcano';
  }
  if (type === 'presidential') {
    return 'geekblue';
  }
  return 'default';
};

export const radioTypeAsResponse = (status) => {
  if (status === '192') {
    return {
      color: 'blue',
      level: '192'
    };
  }
  if (status === '128') {
    return {
      color: 'volcano',
      level: '128'
    };
  }
  if (status === '48') {
    return {
      color: 'lime',
      level: '48'
    };
  }
  return {
    color: 'default',
    level: 'UNKNOWN'
  };
};
