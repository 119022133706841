/**
 * @name Radio EMIA
 * @author Mr. Josia Yvan (M1 MSI_ESTI)
 * @description System API and Management System Software ~ Developed By Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 * @version v0.0.1
 *
 */
import { Result } from 'antd';
import React from 'react';
import useFetchData from '../../hooks/useFetchData';
// import BookingCard from '../dashboard/BookingCard';
import UsersCard from '../dashboard/UsersCard';
import AudioCard from '../dashboard/AudioCard';

function Dashboard() {
  // fetch dashboard API data
  const [loading, error, response] = useFetchData('/dashboard');

  return (
    <div>
      <h2 className='text-[20px] text-center font-text-font font-medium py-4'>
        Welcome to Radio EMIA — Dashboard
      </h2>

      {error ? (
        <Result
          title='Failed to fetch'
          subTitle={error}
          status='error'
        />
      ) : (
        <div className='flex flex-row flex-wrap items-center justify-between gap-2'>
          <UsersCard
            loading={loading}
            data={response?.data?.user}
          />

          <AudioCard
            loading={loading}
            data={response?.data?.audio}
          />
          {/*
          <BookingCard
            loading={loading}
            data={response?.data?.booking_info}
          /> */}
        </div>
      )}
    </div>
  );
}

export default React.memo(Dashboard);
